import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable';
import { authRoles } from '../../auth/authRoles'

const HomeMedical = Loadable(lazy(() => import("./HomeMedical")));
const PayHome = Loadable(lazy(() => import("./components/PayHome")));


const homeRoutes = [
    {
        path: '/home/medical',
        element: <HomeMedical />,
        auth: authRoles.medical,
    },
    {
        path: '/home/medical/pay',
        element: <PayHome />,
        auth: authRoles.medical,
    },
]

export default homeRoutes
