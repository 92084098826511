import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable'
import { authRoles } from '../../auth/authRoles'

const Report = Loadable(lazy(() => import('../schedule/component/reportAppointment/ReportAppointment')));
const ReportClinicHistory = Loadable(lazy(() => import('../patient/component/treatment/report/reportClinicHistory/Report')));
const ReportEvolutionDataOne = Loadable(lazy(() => import('../patient/component/treatment/report/reportEvolutionDataOne/Report')));
const ReportPay = Loadable(lazy(() => import('../patient/component/treatment/report/reportPay/Report')));
const ReportIncapacidad = Loadable(lazy(() => import('../patient/component/treatment/report/reportIncapacidad/Report')));
const ReportAsistencia = Loadable(lazy(() => import('../patient/component/treatment/report/reportAsistencia/Report')));
const ReportPrescription = Loadable(lazy(() => import('../patient/component/treatment/report/reportPrescription/Report')));

const reportRoutes = [
    {
        path: '/agenda/reporte/:id',
        element: <Report />,
        auth: authRoles.guest,
    },
    {
        path: '/dentist/report/clinic/:id/:date',
        element: <ReportClinicHistory />,
        auth: authRoles.guest,
    },
    {
        path: '/dentist/report/evolution/:id',
        element: <ReportEvolutionDataOne />,
        auth: authRoles.guest,
    },
    {
        path: '/dentist/report/prescription/:id',
        element: <ReportPrescription />,
        auth: authRoles.guest,
    },
    {
        path: '/patient/report/pay/:id',
        element: <ReportPay />,
        auth: authRoles.guest,
    },
    {
        path: '/patient/report/incapacidad/:id',
        element: <ReportIncapacidad />,
        auth: authRoles.guest,
    },
    {
        path: '/patient/report/asistencia/:id',
        element: <ReportAsistencia />,
        auth: authRoles.guest,
    }
]

export default reportRoutes
