import React, { lazy } from 'react';
import Loadable from 'app/components/Loadable/Loadable';
import { authRoles } from '../../auth/authRoles';

const TypeIdentification = Loadable(lazy(() => import("./components/typeIdentification/TypeIdentification")));
const TypeGender = Loadable(lazy(() => import("./components/typeGender/TypeGender")));
const TypeEntailment = Loadable(lazy(() => import("./components/typeEntailment/TypeEntailment")));
const Entailment = Loadable(lazy(() => import("./components/entailment/Entailment")));
const TypeMaritial = Loadable(lazy(() => import("./components/typeMaritial/TypeMaritial")));
const TypeStatusMeeting = Loadable(lazy(() => import("./components/typeStatusMeeting/TypeStatusMeeting")));
const Eps = Loadable(lazy(() => import("./components/eps/Eps")));
const TypeActivity = Loadable(lazy(() => import("./components/typeActivity/TypeActivity")));
const EthnicGroup = Loadable(lazy(() => import("./components/ethnicGroup/EthnicGroup")));
const EducationLevel = Loadable(lazy(() => import("./components/educationLevel/EducationLevel")));
const User = Loadable(lazy(() => import("./components/user/User")));
const Location = Loadable(lazy(() => import("./components/location/Location")));
const PatientOptions = Loadable(lazy(() => import("./components/patientOptions/PatientOptions")));
const Bank = Loadable(lazy(() => import("./components/bank/Bank")));
const Report = Loadable(lazy(() => import("./components/report/Report")));
const Rips = Loadable(lazy(() => import("./components/report/Rips")));

const configurationsRoutes = [
    {
        path: '/configurations/TypeStatusMeeting',
        element: <TypeStatusMeeting />,
        auth: authRoles.admin,
    },
    {
        path: '/configurations/TypeIdentification',
        element: <TypeIdentification />,
        auth: authRoles.admin,
    },
    {
        path: '/configurations/TypeGender',
        element: <TypeGender />,
        auth: authRoles.admin,
    },
    {
        path: '/configurations/TypeMaritial',
        element: <TypeMaritial />,
        auth: authRoles.admin,
    },
    {
        path: '/configurations/TypeEntailment',
        element: <TypeEntailment />,
        auth: authRoles.admin,
    },
    {
        path: '/configurations/Entailment',
        element: <Entailment />,
        auth: authRoles.admin,
    },
    {
        path: '/configurations/Eps',
        element: <Eps />,
        auth: authRoles.admin,
    },
    {
        path: '/configurations/EthnicGroup',
        element: <EthnicGroup />,
        auth: authRoles.admin,
    },
    {
        path: '/configurations/EducationLevel',
        element: <EducationLevel />,
        auth: authRoles.admin,
    },
    {
        path: '/configurations/TypeActivity',
        element: <TypeActivity />,
        auth: authRoles.admin,
    },
    {
        path: '/configurations/User',
        element: <User />,
        auth: authRoles.admin,
    },
    {
        path: '/configurations/Location',
        element: <Location />,
        auth: authRoles.admin,
    },
    {
        path: '/configurations/PatientOptions',
        element: <PatientOptions />,
        auth: authRoles.admin,
    },
    {
        path: '/configurations/Bank',
        element: <Bank />,
        auth: authRoles.admin,
    },
    {
        path: '/report/Report',
        element: <Report />,
        auth: authRoles.admin,
    },
    {
        path: '/report/Rips',
        element: <Rips />,
        auth: authRoles.admin,
    },
]

export default configurationsRoutes
