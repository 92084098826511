import React from "react";
import { AppBar, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
    backgroundColor: "#222A45",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
    color: "white",
  },
}));


const AppBarDialogs = ({title, title_accion, accion }) => {
  const CrearAccion = (title_accion, accion) => {
    const resp = accion ? (
      <Button style={{ color: "white" }} onClick={accion.bind(this, false)}>
        {" "}
        {title_accion}{" "}
      </Button>
    ) : (
      ""
    );
    return resp;
  };

  const classes = useStyles();
  return (
    <AppBar className={classes.appBar} elevation={0}>
      <Toolbar>
        <Typography variant="h6" className={classes.title}>
          {title}
        </Typography>
        {CrearAccion(title_accion, accion)}
      </Toolbar>
    </AppBar>
  );
}

export default AppBarDialogs;