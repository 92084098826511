import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable';
import { authRoles } from '../../auth/authRoles'

const Patient = Loadable(lazy(() => import("./PatientInformation")));
const CreatePatient = Loadable(lazy(() => import("./component/dataPatient/CreatePatient")));


const patientRoutes = [
    {
        path: '/patient/view',
        element: <Patient />,
        auth: authRoles.guest,
    },
    {
        path: '/patient/:idPatient/view/:idSchedule',
        element: <Patient />,
        auth: authRoles.guest,
    },
    {
        path: '/patient/create',
        element: <CreatePatient />,
        auth: authRoles.guest,
    },
]

export default patientRoutes
