import React from 'react'
import { Alert } from "@mui/material";
import Snackbar from '@mui/material/Snackbar';

const vertical= 'top';
const horizontal= 'center';


const SnackBarAlertPatient = ({message, openAlert, handleClose, callback}) => {

    return (
        <Snackbar
            open={openAlert}
            anchorOrigin={{ vertical, horizontal }}
            key={vertical + horizontal}
            onClose={handleClose}
            onClick = {() => callback()}
        >
            <Alert variant="filled" className='alertPatient' icon={false}>{message}</Alert>
        </Snackbar>
    )
}
  export default SnackBarAlertPatient;
