import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable';
import { authRoles } from '../../auth/authRoles';

//const Dashboard = Loadable(lazy(() => import("./Dashboard")));
const Client = Loadable(lazy(() => import("./components/client/Client")));
const ClientView = Loadable(lazy(() => import("./components/client/ClientView")));
const Diagnostic = Loadable(lazy(() => import("./components/diagnostic/Diagnostic")));
const Treatment = Loadable(lazy(() => import("./components/treatment/Treatment")));
const Tooth = Loadable(lazy(() => import("./components/tooth/Tooth")));


const dashboardRoutes = [
    {
        path: '/client',
        element: <Client />,
        auth: authRoles.sa,
    },
    {
        path: '/configurations/Tooth',
        element: <Tooth />,
        auth: authRoles.sa,
    },
    {
        path: '/configurations/Diagnostic',
        element: <Diagnostic />,
        auth: authRoles.sa,
    },
    {
        path: '/configurations/Treatment',
        element: <Treatment />,
        auth: authRoles.sa,
    },
    {
        path: '/configuration/:id/view',
        element: <ClientView />,
        auth: authRoles.sa,
    },
]

export default dashboardRoutes
