import React from 'react'
import { Alert } from "@mui/material";
import Snackbar from '@mui/material/Snackbar';

const vertical= 'top';
const horizontal= 'right';

const SnackBarAlert = ({message, openAlert, handleClose, type='error'}) => {

    return (
        <Snackbar
            open={openAlert}
            autoHideDuration={5000}
            anchorOrigin={{ vertical, horizontal }}
            key={vertical + horizontal}
            onClose={handleClose}
        >
            <Alert variant="filled" severity={type} sx={{ width: '100%' }}>{message}</Alert>
        </Snackbar>
    )
}
  export default SnackBarAlert;
