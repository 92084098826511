import AuthGuard from 'app/auth/AuthGuard'
import NotFound from 'app/views/sessions/NotFound'
import chartsRoute from 'app/views/charts/ChartsRoute'
import scheduleRoutes from 'app/views/schedule/ScheduleRoutes'
import homeRoutes from 'app/views/home/HomeRoutes'
import sessionRoutes from 'app/views/sessions/SessionRoutes'
import configurationsRoutes from 'app/views/configurations/ConfigurationsRoutes'
import patientRoutes from 'app/views/patient/PatientRoutes'
import dashboardRoutes from 'app/views/dashboard/DashboardRoutes'
import reportRoutes from 'app/views/reportAllPDF/ReportRoutes'
import MatxLayout from '../components/MatxLayout/MatxLayout'
import { Navigate } from 'react-router-dom'

export const AllPages = () => {
    const all_routes = [
        {
            element: (
                <AuthGuard>
                    <MatxLayout />
                </AuthGuard>
            ),
            children: [...scheduleRoutes, ...chartsRoute, ...configurationsRoutes, ...patientRoutes,
                ...dashboardRoutes, ...homeRoutes],
        },
        ...sessionRoutes, ...reportRoutes,
        {
            path: '/',
            element: <Navigate to="agenda" />,
        },
        {
            path: '*',
            element: <NotFound />,
        },
    ]

    return all_routes
}
