import React, { useState } from 'react';
import { AsyncPaginate } from 'react-select-async-paginate';
import { get } from 'apirest';
import moment from 'moment';


const SelectAsyncPaginate = ({
  value,
  url,
  id,
  onChangeFunc
}) => {
  const [pageNext, setPageNext] = useState(1);

  const loadOptions = async (searchQuery, { page }) => {
    const response = await get(url,
      {
        params: {
          page: pageNext,
          id: id
        }
      });
    if (response.success) {
      if (response.data) {
        if (response.data.maxPage > 1) {
          setPageNext(pageNext + 1);
        }
      }
    }
    return {
      options: response.data.data,
      hasMore: response.data.maxPage > pageNext,
      additional: {
        page: searchQuery ? 2 : page + 1
      }
    };
  };

  const onChange = (option) => {
    if (typeof onChangeFunc === 'function') {
      onChangeFunc(option);
    }
  };

  const type = (data) => {
    let value;
    switch (data) {
      case "1":
        value = 'Permanente';
        break;
      case "2":
        value = 'Temporal';
        break;
      case "3":
        value = 'Mixto';
        break;
      default:
        value = 'Permanente';
    }
    return value;
  }

  return (
    <AsyncPaginate
      key={'paginacion'}
      value={value || ''}
      loadOptions={loadOptions}
      getOptionValue={(option) => option.id}
      getOptionLabel={(option) => `Ver odontograma ${moment(option.created_at).format('DD/MM/YYYY')} - ${type(option.type_teeth)}`}
      onChange={onChange}
      isSearchable={false}
      placeholder="Listado de odontogramas"
      additional={{
        page: 1
      }}
    />
  );
};

export default SelectAsyncPaginate;
