import React, { useState, useEffect } from "react";
import {
  List,
  ListItem,
  Divider,
  ListSubheader,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Typography,
  Grid,
  IconButton,
  ButtonGroup,
} from "@mui/material";
import SearchInput from "./SearchInput";
import FilterListIcon from "@mui/icons-material/FilterList";
import DashboardIcon from "@mui/icons-material/Dashboard";

export default function ListInformation({
  data,
  title,
  filter,
  dashboard,
  actions,
  optionSearch = false
}) {
  const [render, setRender] = useState(data);
  const [search, setSearch] = useState("");
  const [empty, setEmpty] = useState(data.length === 0);

  useEffect(() => {

    setSearch(search);

  }, [search]);

  const filterData = async (value) => {
    if (value.length > 4) {
      let resp = await data.filter(({ mainRow }) =>
        mainRow.toString().toLowerCase().includes(value.toString().toLowerCase())
      );
      setEmpty(resp.length === 0);
      setRender(resp);
      setSearch(value);
    } else if (value.length === 0) {
      setRender(data);
    }
  };

  return (
    <List
      sx={{ width: "100%", bgcolor: "background.paper", marginTop: '10px' }}
      subheader={
        <ListSubheader component="div">
          <Grid container>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6} sx={{ color:'black', textAlign: "left" }}>
              {title}
            </Grid>
            {optionSearch && (
              <Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
                <Grid container>
                  <Grid item xs={12} sm={8} md={10} lg={10} xl={11}>
                    <SearchInput callback={filterData} />
                  </Grid>
                  {(filter || dashboard) && (
                    <Grid item xs={12} sm={3} md={2} lg={2} xl={1} sx={{ textAlign: "left" }}>
                      <ButtonGroup>
                        {filter && (
                          <IconButton
                            onClick={() => filter.event()}
                            aria-label="delete"
                            sx={{
                              backgroundColor: "#D8D8D8",
                              color: "#296DC8",
                              marginTop: 1,
                            }}
                          >
                            <FilterListIcon />
                          </IconButton>
                        )}
                        {dashboard && (
                          <IconButton
                            onClick={() => dashboard.event()}
                            aria-label="delete"
                            color="info"
                            sx={{
                              backgroundColor: "#296DC8",
                              color: "white",
                              marginTop: 1,
                              marginLeft: 1,
                              ":hover": {
                                backgroundColor: "#296DC8",
                              },
                            }}
                          >
                            <DashboardIcon />
                          </IconButton>
                        )}
                      </ButtonGroup>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            )}
          </Grid>
        </ListSubheader>
      }
    >
      {empty && (
        <ListItem sx={{ marginBottom: "100px" }}>
          <Grid container justifyContent="center">
            <Grid item xs={10} sm={10} md={8} lg={8} xl={8} sx={{ textAlign: "center" }}>
              <img src="/assets/imgApp/empty.png" width="200" alt="Empty state" />
              <p>No se encontraron datos</p>
            </Grid>
          </Grid>
        </ListItem>
      )}
      {render.map(({ avatar, mainRow, rows, indexData }, index) => {
        return (
          <div key={index}>
            <ListItem
              alignItems="flex-start"
              secondaryAction={
                <ButtonGroup>
                  {actions && actions(indexData).map((action) => action)}
                </ButtonGroup>
              }
            >
              {avatar && (
                <ListItemAvatar>
                  <Avatar
                    sx={{
                      backgroundColor: avatar.background,
                      color: avatar.color,
                    }}
                  >
                    {avatar.value}
                  </Avatar>
                </ListItemAvatar>
              )}

              <ListItemText
                primary={mainRow ? mainRow : ""}
                secondary={
                  <React.Fragment>
                    {rows.map(({ label, value }, index) => {
                      return (
                        <Typography
                          key={`${index}secondary`}
                          sx={{ display: "block" }}
                          component="span"
                          variant="body2"
                          color="text.primary"
                        >
                          {label}
                          {value}
                        </Typography>
                      );
                    })}
                  </React.Fragment>
                }
              />
            </ListItem>
            <Divider variant="inset" component="li" />
          </div>
        );
      })}
    </List>
  );
}
