export const authRoles = {
    sa: [1], // Only Super Admin has access
    admin: [1, 2], // Only SA & Admin has access
    medical: [1, 2, 3], // Medical and Admin
    //editor: [2, 3], // Only SA & Admin & Editor has access
    guest: [2, 3, 4, 5], // Everyone has access
}
//1=Administrador
//2 supervisor
//3 medico
//4 recepcion
//5 callCenter



// Check out app/views/dashboard/DashboardRoutes.js
// Only SA & Admin has dashboard access

// const dashboardRoutes = [
//   {
//     path: "/dashboard/analytics",
//     component: Analytics,
//     auth: authRoles.admin <===============
//   }
// ];

// Check navigaitons.js

// {
//   name: "Dashboard",
//   path: "/dashboard/analytics",
//   icon: "dashboard",
//   auth: authRoles.admin <=================
// }
